<script>
export default {
    name: "Alert",
	props:{
		isActiveAlert: {
			type: Boolean,
			required: true,
		},
	},
    methods: {
		alertToggle(){
            console.log("alertToggle")
            let vm=this
			vm.$emit("update:isActiveAlert", false);
		}
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
