<script>
export default {
	name: "Menu",
	data(){
		return{
			isActiveMenu: false
		}
	},
	methods: {
		menuToggle(){
			// console.log("menuToggle")
			let vm=this
			vm.isActiveMenu=!vm.isActiveMenu
		}
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
